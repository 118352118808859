import React from "react"
import "./index.scss"

const Button = (props) => (
  <a href={props.link} className="button" download>
    {props.copy}
  </a>
)

export default Button

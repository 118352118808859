import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import Layout from "src/components/layout"
import SubpageBanner from "src/components/SubpageBanner"
import SEO from "src/components/seo"
import SimpleText from "src/components/simple-text"
import Breadcrumbs from "src/components/Breadcrumbs"
import Sidebar from "src/components/Sidebar"
import pressRelease from "src/images/excellerator-press-release.pdf";
import Button from "src/components/Button"

const breadcrumbs = [
  { url: "development", handle: "Development" },
  { url: "development/fda-clearance", handle: "FDA Clearance" },
]

const FdaClearance = () => (
  <StaticQuery
    query={graphql`
      query {
        one: file(relativePath: { eq: "history.jpg" }) {
          childImageSharp {
            fixed(quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        background: file(relativePath: { eq: "discarded-cells-banner.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      const one = data.one.childImageSharp.fixed
      const background = data.background.childImageSharp.fluid

      return (
        <Layout>
          <SEO title="FDA Clearance - ExCellerator" />
          <SubpageBanner image={background} heading="FDA Clearance" />
          <div className="wrapper wrapper--narrow p-bottom p-sides">
            <Breadcrumbs items={breadcrumbs} />
            <div className="split-contain wrapper">
              <div className="split-contain__sidebar">
                <Sidebar image={one} />
              </div>
              <div className="split-contain__contain">
                <SimpleText>
                  <h2 style={{ color: "black", maxWidth: "none", lineHeight: 1.25 }}>
                    The{" "}
                    <strong style={{ color: "#9C69AC" }}>exCellerator™</strong>{" "}
                    Cervical Collection Device is now{" "}
                    <strong style={{ color: "#9C69AC" }}>FDA Cleared!</strong>
                  </h2>
                  <br />
                  <h2 style={{ color: "black", maxWidth: "none", lineHeight: 1.25 }}>
                    <em>
                      This clears the way for all clinicians to utilize this
                      innovative device for the benefit of all women having
                      cervical cancer screening.
                    </em>
                  </h2>

                  <h3>INTENDED USE STATEMENT:</h3>

                  <p>The exCellerator™ Cervical Collection Device is a cervical spatula intended for collection of ectocervical specimen for the ThinPrep Pap test. The device will also be used to aid in a more complete transfer of the specimen material from the endocervical brush (cytobrush), to the PreservCyt solution for ThinPrep Pap testing.</p>

                  <p>The ExCellerator device must be used with IVD labeled endocervical brush.</p>

                  <div className="center"><Button link={pressRelease} download={true} copy="Press Release" /></div>
                </SimpleText>
              </div>
            </div>
          </div>
        </Layout>
      )
    }}
  />
)

export default FdaClearance
